body > #root > div {
  height: 100%vh;
}

header {
  /* background: transparent; */
  /* background-color: transparent; */
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  transition: .5s;
}

header a {
  margin-left: 24px;
  margin-right: 24px;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h2 .subtitle {
  margin-top: 20px;
}

.mainContent {
  margin: 40px auto 0px auto;
  max-width: 650px;
  line-height: 1.6;
  font-size: 18px;
  padding: 0 10px;
  transition: .5s;
}

.mainContentFun {
  margin: 40px auto 0px auto;
  max-width: 650px;
  line-height: 1.6;
  font-size: 18px;
  padding: 0 10px;
  transition: .5s;
}

.App {
  height: 100%vh;
  transition: .5s;
}
.container {
  transition: .5s;

}
.zack {
  color: #d1cdcd;
  font-family: Roboto Slab;
}

.mckenna {
  color: #d1cdcd;
  font-family: Roboto Slab;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 550px) {
  header a {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .hiImZack {
    font-size: 8vw;
  }
  .response{
    font-size: 8vw;
  }
  #darkModeButton {
    display: none;

  }
}

.response {
}

.clickCounter {
  padding-top: 1rem;
  border: 1px;
  text-align: center;
  border-color: inherit;
}

.hiImZack:hover {
  /* color: rgb(80, 186, 212); */
  cursor: pointer;
  /* transition: .7s */
}

.response:hover {
  /* color: rgb(80, 186, 212); */
  cursor: pointer;
  /* transition: .7s; */
}


@keyframes goDark {
  0% {
    background-color: #e9e9e9;
  }
  100% {
    background-color: #343a40;
  }
}

@keyframes goLight {
  0% {
    background-color: #343a40;
  }
  100% {
    background-color: #e9e9e9;
  }
}

.goDarkMode {
  animation: goDark .5s;
}

.goLightMode {
  animation: goLight .5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in {
  opacity: 0;
}

.fade-in.visible {
  transition: opacity 1s ease-in;
  opacity: 1;
}
